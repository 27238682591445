<template>
    <div class="main-content">
        <div class="dark-blue-text label-font">
            利用するサービスを選択してください
        </div>
        <br>
        <br>
        <br>
        <div class="row reports flexbox" style="align-items: center; margin: auto;">
            <div class="col-md-3 col-lg-3 col-xl-3 mb-3" :class="{'col-3': active}">
                <div class="report-item d-flex align-items-center" style="padding:5px;height:200px;">
                    <router-link :to="{ name: 'userRoute-staging-new-request' }" class="link-text">
                        <div>
                            <h5 class="new-request">
                                <span class="title">デジタルステージング</span>
                                <br>
                                スマホ等のカメラで撮った写真にCG加工でホームステージングを行います。
                            </h5>
                            <div class="img-area" style="display:flex; justify-content:center">
                                <div class="img1" style="width:50%">
                                    <img
                                    class="shadow-1 border-radius-1"
                                    src="/img/staging/before_staging.jpg"
                                    alt="before_staging"
                                    width="100%"
                                    />
                                </div>

                                <div class="img2" style="width:50%">
                                    <img
                                    class="shadow-1 border-radius-1"
                                    src="/img/staging/after_staging.jpg"
                                    alt="before_staging"
                                    width="100%"
                                    />
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>


            <div class="col-md-3 col-lg-3 col-xl-3 mb-3" :class="{'col-3': active}">
                <div class="report-item d-flex align-items-center" style="padding:5px;height:200px;">
                    <router-link :to="{ name: 'userRoute-staging-new-360-request' }" class="link-text">
                        <div>
                            <h5 class="new-request">
                                <span class="title">360°デジタルステージング</span>
                                <br>
                                360°専用カメラで撮った写真にCG加工でホームステージングを行います。
                            </h5>
                            <div class="img-area" style="display:flex; justify-content:center">
                                <div class="img1" style="width:50%">
                                    <img
                                    class="shadow-1 border-radius-1"
                                    src="/img/staging/before_360.jpg"
                                    alt="upload-modal-model-1"
                                    width="100%"
                                    />
                                </div>

                                <div class="img2" style="width:50%">
                                    <img
                                    class="shadow-1 border-radius-1"
                                    src="/img/staging/after_360.jpg"
                                    alt="upload-modal-model-1"
                                    width="100%"
                                    />
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>


            <div class="col-md-3 col-lg-3 col-xl-3 mb-3" :class="{'col-3': active}">
                <div class="report-item d-flex align-items-center" style="padding:5px;height:200px;">
                    <router-link :to="{ name: 'userRoute-delete-new-request' }" class="link-text">
                        <div>
                            <h5 class="new-request">
                                <span class="title">家具消し</span>
                                <br>
                                すでに設置されてある家具家電を消します。
                            </h5>
                            <div class="img-area" style="display:flex; justify-content:center">
                                <div class="img1" style="width:50%">
                                    <img
                                    class="shadow-1 border-radius-1"
                                    src="/img/staging/before_delete.jpg"
                                    alt="upload-modal-model-1"
                                    width="100%"
                                    />
                                </div>

                                <div class="img2" style="width:50%">
                                    <img
                                    class="shadow-1 border-radius-1"
                                    src="/img/staging/after_delete.jpg"
                                    alt="upload-modal-model-1"
                                    width="100%"
                                    />
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

        </div>

        <div class="row reports flexbox" style="align-items: center; margin: auto;">
            <div class="col-md-3 col-lg-3 col-xl-3 mb-3" :class="{'col-3': active}">
                    <div class="report-item d-flex align-items-center" style="padding:5px;height:200px;">
                        <router-link :to="{ name: 'userRoute-office-new-request' }" class="link-text">
                            <div>
                                <h5 class="new-request">
                                    <span class="title">商業用デジタルステージング</span>
                                    <br>
                                    スマホ等の通常のカメラで撮った写真にCG加工でオフィス用のステージングを行います。
                                </h5>
                                <div class="img-area" style="display:flex; justify-content:center">
                                    <div class="img1" style="width:50%">
                                        <img
                                        class="shadow-1 border-radius-1"
                                        src="/img/staging/office_before.jpg"
                                        alt="upload-modal-model-1"
                                        width="100%"
                                        />
                                    </div>

                                    <div class="img2" style="width:50%">
                                        <img
                                        class="shadow-1 border-radius-1"
                                        src="/img/staging/office_after.jpg"
                                        alt="upload-modal-model-1"
                                        width="100%"
                                        />
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-xl-3 mb-3" :class="{'col-3': active}">
                </div>
                <div class="col-md-3 col-lg-3 col-xl-3 mb-3" :class="{'col-3': active}">
                </div>

        </div>
    </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';
// Import Services
import {
    cus_stagingService_GetSRData,
    cus_stagingService_GetAllSRItems,
    cus_stagingService_CreateSR,
    cus_stagingService_successInvoicePayment,
} from '../../services/customer/staging';

export default {
    metaInfo() {
        return {
            title: 'Digital Staging - Staging | Select Survice',
        };
    },

    components: {
    },

    computed: {
        ...mapGetters(['currentUser', 'staging']),
    },

    data() {
        return {
        accessToken: localStorage.getItem('_accessToken'),
        requestID: localStorage.getItem('_stagingRequestID'),
        steps: {
            completed: [],
            current: 1,
            proceedDisabled: false,
        },
        is_invoice_payment: null,
        responseId: null,
        active: true
        };
    },

    created() {
        this.resize();
    },

    mounted() {
        this.resize();
    },

    methods: {
        resize: function () {
            if (window.innerWidth <= 767) {
                this.active = false
            } else {
                this.active = true
            }
        }
    },
};
</script>

<style scoped>
.reports .col-6 .report-item {
    height: 150px;
    width: 100%;
    padding: 0px 40px 0px 40px;
    color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.link-text {
    color: #000;
    text-decoration: none;
}

.new-request {
    font-size:12px;
}

.title {
    font-size:14px;
}

.img1 {
  margin: 0 5px;
}
.img2 {
  margin: 0 5px;
}

@media only screen and (max-width: 1440px) {
    .reports .col-6 .report-item {
        padding: 10px 20px 10px 20px;
    }

    .flexbox {
        display:flex;
        justify-content: center;
        flex-wrap:wrap;
    }
}

.reports .report-item {
    background: #fff;
    border: 1px solid #1f7ce5;
    color: #1f7ce5;
    padding: 0;
    width: 100%;
    text-align: center;
}

.reports .col-6 .report-item .d-block:nth-child(1) {
    height: 100px;
    width: 100%;
}

.reports .col-6 .report-item .d-block:nth-child(2) {
    height: 50px;
    width: 100%;
}

.reports .col-6 .report-item .icon {
    margin-top: 25px;
}

.reports .col-6 .report-item .count {
    margin-top: 14px;
    font-size: 60px;
}

@media only screen and (max-width: 1599px) {
    .reports .col-6 .report-item .title {
        font-size: 14px;
    }

    .flexbox {
        display:flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 1440px) {
    .reports .col-6 .report-item .icon {
        font-size: 40px;
    }

    .reports .col-6 .report-item .count {
        margin-top: 10px;
        font-size: 60px;
    }

    .flexbox {
        display:flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 767px) {
    .flexbox {
        display:flex;
        flex-direction: column;
        justify-content: center;
        width: 380px;
    }
}
</style>
